import Headroom from 'headroom.js'

function init () {
  const header = document.querySelector('.site-header')
  if (header) {
    const headroom = new Headroom(header, {
      offset: 200,
      tolerance: 5,
      classes: {
        // when element is initialised
        initial: 'headroom',
        // when scrolling up
        pinned: 'headroom--pinned',
        // when scrolling down
        unpinned: 'headroom--unpinned'
      }
    })
    headroom.init()
  }
}

export default {
  init
}
