/* global $ */
import Headroom from 'headroom.js'

import './jquery.slicknav.js'

import './slicknav.css'

const setSlickNav = async () => {
  $('#menu').slicknav()
  let logo = document.querySelector('.logo') // Get logo
  if (logo) { // Add logo
    let slickNavElem = document.querySelector('.slicknav_menu')
    let logoElem = document.createElement('div')
    logoElem.className = 'slicknav_menu__logo'
    logoElem.innerHTML = logo.innerHTML
    slickNavElem.appendChild(logoElem)
    return slickNavElem
  }
}

const setHeadroom = (slickNavElem) => {
  if (slickNavElem) {
    const headroom = new Headroom(slickNavElem, {
      offset: 200,
      tolerance: 5,
      classes: {
        // When element is initialised
        initial: 'headroom--mobile',
        // When scrolling up
        pinned: 'headroom--pinned--mobile',
        // When scrolling down
        unpinned: 'headroom--unpinned--mobile'
      }
    })
    headroom.init()
  }
}

const init = async () => {
  await setSlickNav().then(setHeadroom) // Initialize SlickNav, then set headroom
}

export default {
  init
}
